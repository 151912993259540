var headers = {
    "X-APP-DeviceID": 'web',   //If your header name has spaces or any other char not appropriate
    "X-APP-Platform": 'web',
    "X-APP-Signature": 'web',
    //"X-APP-Session": session,
    "X-APP-Date": 'date'
};

/**
 * Created by ciuc on 11/16/2015.
 */
// Master object
var evo = {

    /**
     * Constructor
     */
    init: function () {
        Auth().init();
        this.setDefaults();
        this.setEnvironment();
        this.menu();
        this.layout();
        this.startDependencies();
        this.register();

    },

    /**
     * Default object variables
     */
    setDefaults: function () {
        environment = null;
        var activePage = $("#menu-home");
    },

    /**
     * Set the bootstrap environment according to the device being used
     */
    setEnvironment: function () {

        function findBootstrapEnvironment() {
            var envs = ["xs", "sm", "md", "lg"];

            var $el = $('<div>');
            $el.appendTo($('body'));

            for (var i = envs.length - 1; i >= 0; i--) {
                var env = envs[i];

                $el.addClass('hidden-' + env);
                if ($el.is(':hidden')) {
                    $el.remove();
                    return env;
                }
            }
        }

        environment = findBootstrapEnvironment();
    },

    /**
     *  My menu object
     */
    menu: function () {

        /**
         * Main call
         */
        //init();

        /**
         * Constructor for the menu
         */
        function init() {

            setEvents();
            addActiveClassToMenu();
        }

        /**
         * Add the class "active" to the menu links according to where the user is navigating
         */
        function addActiveClassToMenu() {
            function stripTrailingSlash(str) {
                if (str.substr(-1) == '/') {
                    return str.substr(0, str.length - 1);
                }
                return str;
            }

            var url = window.location.pathname;
            var activePage = stripTrailingSlash($('.nav li a').attr('href') + url);

            $('.nav li a').each(function () {
                var currentPage = stripTrailingSlash($(this).attr('href'));
                if (activePage == currentPage) {
                    $(this).parent().addClass('active');
                }
            });
        }


        /**
         * Setter for the menu events
         */
        function setEvents() {
            $("#menu-tour").click(function () {

                bootbox.confirm("Are you sure?", function (result) {
                    //Example.show("Confirm result: "+result);
                });

            });

        }
    },

    /**
     *  Functions to be used for handling validation errors
     */
    layout: function () {

        function show_form_errors($form, error_array) {
            AppLayout.remove_form_errors($form);

            if ($.isEmptyObject(error_array)) {
                return;
            }

            $.each(error_array, function (field_name, errors) {
                var $field = $form.find('[name="' + field_name + '"]');
                var $field_container = $field.parents('.form-group');
                $field_container.addClass('has-error');

                /* For cases where we have addons on inputs, or select2 or any other type of field that brake the layout
                 * we make adjustments
                 */
                if ($field.parent().hasClass('input-group')) {
                    $field = $field.parent();
                }
                // display only the first error after the field
                $field.after('<span class="message-block">' + errors[0] + '</span>');
            });
        }

        function remove_form_errors($form) {
            $form.find('.has-error').removeClass('has-error');
            $form.find('.message-block').remove();
        }
    },

    /**
     *  JS dependencies
     */
    startDependencies: function () {

        init();

        function init() {
            selectPicker();
        }

        /**
         * Dropdown prettyfier
         */
        function selectPicker() {
            var selectpicker = $('.selectpicker');
            selectpicker.selectpicker();
            selectpicker.selectpicker("width", 'auto');
        }

    },

    /**
     * Register page
     */
    register: function () {
        init();
        function init() {
            useMetric();
        }

        /**
         *  Set units of measure
         */
        function useMetric() {
            $("#units").on('change', function () {
                var selected = $(this).find("option:selected").val();

                if (selected == "imperial") {
                    document.getElementById('heightUnit1').textContent='ft';
                    //document.getElementById('heightUnit2').textContent='in';

                    document.getElementById('weightUnit').textContent='lb';
                }
                else if(selected == "metric")
                {
                    document.getElementById('heightUnit1').textContent='cm';
                    //document.getElementById('heightUnit2').textContent='cm';
                    document.getElementById('weightUnit').textContent='kg';
                }

            });
        }

        function uploadPhoto(){
            $("#photo").on('click', function(){
                alert('a');
            });
        }
    }


};
$(document).ready(function () {



    // Init our master object
    evo.init();

    //if ( $("input[type='checkbox']").length > 0 ) {
    //    $("input[type='checkbox']").iCheck({
    //        checkboxClass: 'icheckbox_minimal'
    //    });
    //}

    if ( $('#upload-photo').length >0  ) {
        $('#upload-photo').fileupload({
            headers:headers,
            done: function(e, data){
                if ( data.result.success == false ) {
                    alert(data.result.error);
                } else {
                    $('.user-reg-foto img').attr('src',data.result.photo_original).show();
                    $('.photo_url').val(data.result.photo_url);
                    $('.fileUpload_title').html('');
                    $('.user-photo-edit img').attr('src',data.result.photo_original).show();
                }
            }
        });
    }

    if ($('#upload_image').length >0  ) {
        $('#upload_image').fileupload({
            headers:headers,
            done: function(e, data){
                if ( data.result.success == false ) {
                    alert(data.result.error);
                } else {
                    $('.user-photo-edit img').attr('src',data.result.photo_original).show();
                }
            }
        });
    }

    $( "#newsletter-form" ).submit(function( event ) {
        // process the form
        $.ajax({
                type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
                url         : $(this).attr('action'), // the url where we want to POST
                data        : $(this).serialize(), // our data object
                dataType    : 'json', // what type of data do we expect back from the server
                encode          : true
            })
            // using the done promise callback
            .done(function(data) {

                // log data to the console so we can see
                console.log(data);

                // here we will handle errors and validation messages
            });
        event.preventDefault();
    });


});

$(document).ready(function(){

    var widthAppCenter = $('.app-center').width();

    $('.app-header-date').css('width' , widthAppCenter +1 + 'px'  );
    $('.tab-section-center').css('width' , widthAppCenter +1 + 'px' );

    $(window).on('resize', function(){
        var widthAppCenter = $('.app-center').width();

        $('.app-header-date').css('width' , widthAppCenter +1 + 'px' );
        $('.tab-section-center').css('width' , widthAppCenter +1 + 'px' );
    });

    $('#scrollbox3').enscroll({
        showOnHover: false,
        verticalTrackClass: 'track3',
        verticalHandleClass: 'handle3'
    });

});