/**
 * Created by ciuc on 11/25/2015.
 */
var Auth = function() {
    var $login_form = $('#login-form');
    var $register_form = $('#register-form');

    var register_events = function() {

        $login_form.submit(function(e) {
            e.preventDefault();
            handle_login($(this));
        });
        $register_form.submit(function(e) {
            e.preventDefault();
            handle_register($(this));
        });
    }

    var handle_login = function($form) {
        $('.btn-primary').prop('disabled', true);
        $.ajax({
            url: base_url + 'auth/login',
            type: 'POST',
            dataType: 'json',
            data: $form.serialize()
        }).done(function(response) {
            console.log(response);
            window.location.href = response.url;
        }).fail(function(error) {
            AppLayout.show_form_errors($form, error.responseJSON.error_array);
            AppLayout.alert({
                container: $form,
                type: 'danger',
                message: error.responseJSON.error_message
            });
        }).always(function() {
            $('.btn-primary').prop('disabled', false);
        });
    };

    var handle_register = function($form) {
        $('.btn-primary').prop('disabled', true);
        $.ajax({
            url: base_url + 'auth/register',
            type: 'POST',
            dataType: 'json',
            data: $form.serialize()
        }).done(function(response) {
            window.location.href = response.url;
        }).fail(function(error) {
            AppLayout.show_form_errors($form, error.responseJSON.error_array);
            AppLayout.alert({
                container: $form,
                type: 'danger',
                message: error.responseJSON.error_message
            });
        }).always(function() {
            $('.btn-primary').prop('disabled', false);
        });
    };



    return {
        init: function() {
            register_events();
        }
    }
}