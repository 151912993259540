var AppLayout = {
    loader: $('#loader-wrapper'),
    header_block: $('.app-header'),
    app_section: $('section.app'),
    app_container: $('.app-container'),
    center_block: $('.app-center'),
    right_block: $('.app-right'),
    left_block: $('section.user'),
    left_menu: $('.left-menu'),
    center_bottom_block: $('.app-center-bottom'),
    main_gogoasa: $('.app-center-circle'),
    scroller: $('.scroller'),
    footer: $('footer'),

    init: function() {
        AppLayout.register_events();
        AppLayout.adjust_layout();
        AppLayout.init_plugins();
    },

    init_after_ajax: function() {
        // init stuff after ajax call
    },

    register_events: function() {
        $(window).resize(function() {
            evo.setEnvironment();
            AppLayout.adjust_layout();
        });

        $(window).load(function() {
            AppLayout.loader.fadeOut();
        });

        //$(document).bind('touchmove',function(e) {
        //    e.preventDefault();
        //});

    },

    adjust_layout: function() {
        if(typeof environment == 'undefined') {
            return;
        }
        AppLayout.adjust_blocks();

        //AppLayout.scroller.height(app_center_height);
        AppLayout.adjustMain();
        AppLayout.adjustDiary();

    },

    adjust_blocks: function() {
        switch (environment) {
            case 'lg':
                AppLayout.center_block.css('min-height', $(window).height() - AppLayout.header_block.outerHeight() - AppLayout.footer.outerHeight());
                AppLayout.center_block.width($(window).width() - AppLayout.left_menu.outerWidth() - AppLayout.left_block.outerWidth() - AppLayout.right_block.outerWidth()-1);
                AppLayout.center_bottom_block.animate({bottom:  0}, 500);
                AppLayout.app_section.width(AppLayout.center_block.outerWidth() + AppLayout.right_block.outerWidth());
                $('body').find('.for-sm').removeClass('for-sm');
                $('body').find('.for-xs').removeClass('for-xs');
                $('body').not('.auth-body').addClass('no-scroll');
                    //AppLayout.center_bottom_block.css('position','absolute');

                $('body').addClass('no-scroll'); // allow scrolling
                AppLayout.center_bottom_block.removeClass('for-xs');
                AppLayout.header_block.find('.app-header-sync').removeClass('for-sm');
                AppLayout.right_block.removeClass('for-sm');
                AppLayout.left_block.css('width','300px');
                AppLayout.left_block.insertBefore(AppLayout.app_section);
                AppLayout.left_block.outerHeight((left_side_height > $(window).height() ? left_side_height : $(window).height()-10)).insertAfter(AppLayout.left_menu);;
                break;


            case 'md':
                AppLayout.center_block.css('min-height', $(window).height() - AppLayout.header_block.outerHeight() - AppLayout.footer.outerHeight());
                AppLayout.center_block.width($(window).width() - AppLayout.left_menu.outerWidth() - AppLayout.left_block.outerWidth() - AppLayout.right_block.outerWidth()-1);
                AppLayout.center_bottom_block.animate({bottom:  0}, 500);
                AppLayout.app_section.width(AppLayout.center_block.outerWidth() + AppLayout.right_block.outerWidth());
                $('body').find('.for-sm').removeClass('for-sm');
                $('body').find('.for-xs').removeClass('for-xs');
                $('body').not('.auth-body').addClass('no-scroll');
                //AppLayout.center_bottom_block.css('position','relative');
                break;

            case 'sm':
                $('body').removeClass('no-scroll'); // allow scrolling
                AppLayout.center_bottom_block.addClass('for-sm');
                AppLayout.header_block.find('.app-header-sync').addClass('for-sm');
                AppLayout.right_block.addClass('for-sm');
                AppLayout.center_block.height('auto');

                var left_side_height = AppLayout.center_block.height() + AppLayout.right_block.height() + AppLayout.header_block.height();

                AppLayout.left_block.addClass('for-sm').removeAttr('style');
                AppLayout.left_block.outerHeight((left_side_height > $(window).height() ? left_side_height : $(window).height()-10)).insertAfter(AppLayout.left_menu);;
                AppLayout.left_menu.outerHeight(AppLayout.left_block.outerHeight());
                AppLayout.center_block.outerWidth($(window).width() - AppLayout.left_menu.outerWidth() - AppLayout.left_block.outerWidth());
                AppLayout.app_section.width(AppLayout.center_block.width());
                AppLayout.center_bottom_block.animate({bottom:  1,position: 'absolute'}, 500);
                AppLayout.center_bottom_block.css('position','absolute');
                $('body').find('.for-xs').removeClass('for-xs');

                break;

            case 'xs':
                $('body').removeClass('no-scroll'); // allow scrolling
                AppLayout.center_bottom_block.addClass('for-xs');
                AppLayout.header_block.find('.app-header-sync').addClass('for-sm');
                AppLayout.right_block.addClass('for-sm');
                AppLayout.center_block.height('auto');
                AppLayout.center_block.outerWidth($(window).width());
                AppLayout.app_section.width(AppLayout.center_block.width());
                AppLayout.left_block.outerWidth(AppLayout.center_block.width()).insertAfter(AppLayout.center_block);
                AppLayout.left_block.height('auto');
                AppLayout.left_menu.height(AppLayout.center_block.height() + AppLayout.right_block.height() + AppLayout.header_block.height() + AppLayout.left_block.height());
                AppLayout.app_container.css({'padding-bottom': '48px'});
                AppLayout.center_bottom_block.animate({bottom:  0}, 500);
                //AppLayout.center_bottom_block.css('position','relative');
                break;
        }

    },

    adjustMain: function() {
        var center_bottom_block_width = AppLayout.center_bottom_block.width();
        var $meals = AppLayout.center_bottom_block.children();

        // adjust meals
        $meals.each(function (i, v) {
            if(center_bottom_block_width < 314) {
                var coef = 1;
            }
            else if(center_bottom_block_width < 500) {
                var coef = 3;
            }
            else {
                var coef = 6;
            }
            $(v).outerWidth(center_bottom_block_width / coef);
        });

        var gogoasa_dimensions = (AppLayout.center_block.width() <= AppLayout.center_block.height() ? AppLayout.center_block.width() : AppLayout.center_block.height()) / 2;
        AppLayout.main_gogoasa.css({
            width: gogoasa_dimensions,
            height: gogoasa_dimensions,
            'border-width': gogoasa_dimensions * 0.1
        });

        AppLayout.main_gogoasa.find('.app-center-circle-kcal').css({
            'font-size': gogoasa_dimensions * 0.08,
            'line-height': gogoasa_dimensions * 0.08 + 'px',
        });
        AppLayout.main_gogoasa.find('.app-center-circle-kcal-val').css({
            'font-size': gogoasa_dimensions * 0.18,
            'line-height': gogoasa_dimensions * 0.20 + 'px',
        });
        AppLayout.main_gogoasa.find('.app-center-circle-from').css({
            'font-size': gogoasa_dimensions * 0.08,
            'line-height': gogoasa_dimensions * 0.08 + 'px',
        });
        AppLayout.main_gogoasa.find('.app-center-circle-from-val').css({
            'font-size': gogoasa_dimensions * 0.15,
            'line-height': gogoasa_dimensions * 0.17 + 'px',
        });
        AppLayout.main_gogoasa.find('.app-center-circle-bonus-val').css({
            'font-size': gogoasa_dimensions * 0.08,
            'line-height': gogoasa_dimensions * 0.08 + 'px',
        });

    },

    adjustDiary: function() {
        //var intakes_wrapper_width = $('.app-center-diary-item-more-info .wrapper').innerWidth();
        //var $intakes = $('.app-center-diary-item-more-info').find('.calories_item');
        //
        //$intakes.each(function (i, v) {
        //    if(intakes_wrapper_width > 330) {
        //        var coef = 1;
        //    }
        //    else {
        //        var coef = 2;
        //    }
        //    $(v).width(intakes_wrapper_width / coef);
        //});
    },

    alert: function(params) {
        var defaults = {
            container: $('body'),
            place: 'prepend',
            type: 'success',
            message: '...',
            dismiss: 0,
        };
        $.each(defaults, function(key, value) {
            if(typeof params[key] == 'undefined') {
                params[key] = value;
            }
        });
        // remove the current alert if exists in the container
        $(params.container).find('.alert').remove();

        // create the alert
        var $html = $('<div class="alert alert-'+params.type+' alert-dismissible fade in" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">x</span></button>' +
            params.message +'</div>');
        if(params.place == 'append') {
            $(params.container).append($html);
        } else {
            $(params.container).prepend($html);
        }

        // dismiss the modal
        if(params.dismiss != 0) {
            setTimeout(function() {
                $html.fadeOut();
            }, params.dismiss);
        }
    },

    show_form_errors: function($form, error_array) {
        AppLayout.remove_form_errors($form);

        if($.isEmptyObject(error_array)) {
            return;
        }

        $.each(error_array, function(field_name, errors) {
            var $field = $form.find('[name="'+field_name+'"]');
            var $field_container = $field.parents('.form-group');
            $field_container.addClass('has-error');

            /* For cases where we have addons on inputs, or select2 or any other type of field that brake the layout
             * we make adjustments
             */
            if($field.parent().hasClass('input-group')) {
                $field = $field.parent();
            }

            if($field.attr('type') == 'checkbox') {
                $field.parent().find('label').addClass('has-error');
                return;
            }
            // display only the first error after the field
            $field.after('<span class="message-block">' + errors[0] + '</span>');
        });
    },

    remove_form_errors: function($form) {
        $form.find('.has-error').removeClass('has-error');
        $form.find('.message-block').remove();
    },

    block: function(container, msg) {
        var $container = typeof container != 'undefined' ? $(container) : $('body');
        var msg = typeof msg != 'undefined' ? msg : '';

        var overlay = '<div class="overlay"><span class="overlay_msg">'+msg+'</span></div>';
        $container.append(overlay);
    },

    unblock: function(container) {
        var $container = typeof container != 'undefined' ? $(container) : $('body');
        $container.find('.overlay').remove();
    },

    init_plugins: function() {
        // plugins to inti here
    }

}

$(document).ready(function() {
    AppLayout.init();
});
